.side-bar {
    width: 20%;
    height: 100vh;
    background: white;
    border-radius: 0 12px 12px 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: inline-block;
}

.side-option>li {
    text-align: center;
    height: 60px;
    border-bottom: 1px solid #efefeb;
    align-content: center;
    font-size: x-large;
}

.btn-custom {
    margin: 10px;
    border: 2px solid;
    padding: 10px;
    border-radius: 33px;
    color: #4e2b43;
    font-size: 15px;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile:focus+label,
.inputfile+label:hover {
    background-color: #4e2b43;
    color: white;
}

.inputfile+label {
    cursor: pointer;
    /* "hand" cursor */
}

.box {
    width: max-content;
    /* padding: 10px; */
    border: 2px solid #9ff2ff;
    position: absolute;
}

.option-text {
    border: 1px solid black;
    border-radius: 15px;
    overflow: hidden;
    text-align: center;
    align-content: center;
    background: #846379;
}

.option-text>input {
    border: none;
    width: 90%;
    padding: 10px;
    border-right: 1px solid;
    margin-right: 7px;
}

.option-text>input:focus {
    outline: none;
}

.option-text>i {
    float: right;
    font-size: x-large;
    margin-top: 7px;
    color: white;
    padding-right: 6px;
}

.text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.text-container>h3 {
    margin: 0;
}

.text-container>i {
    font-size: medium;
}

.main-image {
    background-size: 100% 100% !important;
    min-height: 100vh;
}