.slider {
    position: relative;
  }
  
  .slides-container {
    display: flex;
    overflow: hidden;
  }
  
  .slide {
    display: none;
  }
  
  .slide.active {
    display: block;
  }
  
  img {
    max-width: 100%;
  }
  
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 100;
  }
  
  .prev {
    left: 0;
  }
  
  .next {
    right: 0;
  }
  