body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
	background: #ffc824;
	border: medium none;
	color: #222;
	padding: 8px 20px;
	font: 500 13px/24px "Poppins",Helvetica,sans-serif;
	text-transform: capitalize;
	display: inline-block;
	letter-spacing: 1px;
	cursor: pointer;
	position: relative;
	z-index: 2;
	border-radius: 25px;
	-moz-border-radius: 25px;
	-webkit-border-radius: 25px;
	-khtml-border-radius: 25px;
	overflow: hidden;
}
.btn-primary::after {
	background: #b30116;
	border-radius: 25px;
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transform-origin: 50% 0;
	transform-origin: 50% 0;
	-webkit-transition-property: transform;
	transition-property: transform;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out;
}

.btn-primary:hover{
  background: #b30116 !important;  
}
